<template>
  <div>
    <div>
      <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="100px" class="demo-dynamic">
        <el-row style="margin-bottom: 10px">
          <span class="tip">提示：请填写邮件的收件人，若此处留空直接提交，将按照ERP客户信息里预留的邮箱发送!</span>
        </el-row>
        <el-row>
          <el-form-item
            v-for="(domain, index) in dynamicValidateForm.domains"
            :label="'收件人' + (index + 1)"
            :key="domain.key"
            :prop="'domains.' + index + '.value'"
          >
            <el-input v-model="domain.value" class="inputCss" :disabled="true"></el-input>
            <el-button type="danger" @click.prevent="removeDomain(domain)">删除</el-button>
            <el-button type="primary" @click="importCustMail(index)">导入</el-button>
            <el-button :disabled="!domain.value ? true : false" type="info" @click="saveInfo1('dynamicValidateForm', index)"
              >预览</el-button
            >
          </el-form-item>
        </el-row>
        <div class="vd_right" v-if="htmlUrl"><i class="el-icon-error" @click="closeHtml"></i></div>
        <div>
          <div v-html="htmlUrl"></div>
        </div>
        <el-row class="box-card">
          <el-form-item>
            <el-button type="primary" @click="submitForm('dynamicValidateForm')">提交</el-button>
            <el-button @click="addDomain">增加收件人</el-button>
            <el-button @click="resetForm('dynamicValidateForm')">重置</el-button>
          </el-form-item>
        </el-row>
      </el-form>
      <el-dialog :title="title" :visible.sync="dialogVisible" width="50%" :before-close="handleClose" append-to-body>
        <el-table ref="multiTable" :data="custMailList" @row-dblclick="dbClickJp" border class="mtrTab">
          <el-table-column label="设计师名称" prop="param1" width="200" align="center" />
          <el-table-column label="邮箱" prop="param2" align="center" />
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { smplAPI } from '@api/modules/smpl';
import { optnAPI } from '@api/modules/optn';

export default {
  name: 'SmplEditMail',
  props: {
    smplPropList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tempMail: null,
      custMailList: [],
      title: '导入收件人',
      dialogVisible: false,
      mailForm: {
        mail_company: '',
        mail_odd: ''
      },
      dynamicValidateForm: {
        domains: [
          {
            value: ''
          }
        ],
        email: ''
      },
      chooseNum: 0,
      htmlUrl: ''
    };
  },
  created() {
    this.getCustMail();
  },
  methods: {
    getCustMail() {
      get(optnAPI.getOptnByPermId, { perm_id: 10011 })
        .then(res => {
          if (res.data.code === 0) {
            this.custMailList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    //取消
    cancel() {
      this.$emit('MailCancel');
    },
    closeHtml() {
      this.htmlUrl = '';
    },
    saveInfo1(val, val1) {
      // 接收smpl_id
      let temp = [];
      for (let i in this.smplPropList) {
        temp.push(this.smplPropList[i].smpl_id);
      }
      // 接收requ_id
      let temp2 = [];
      for (let i in this.smplPropList) {
        temp2.push(this.smplPropList[i].requ_id);
      }
      // 接收件人
      let temp3 = [];
      // for (let i in this.dynamicValidateForm.domains) {
      //     var reg = /(?<=\()(.+?)(?=\))/g;
      //     var matchResult = this.dynamicValidateForm.domains[i].value.match(reg);
      //     temp3.push(matchResult[0])
      // }
      let reg = /(?<=\()(.+?)(?=\))/g;
      temp3 = this.dynamicValidateForm.domains[val1].value.match(reg);
      console.log('temp3', temp3, this.dynamicValidateForm.domains);
      post(smplAPI.addSmplMail, {
        requ_id_list: temp2,
        smpl_id_list: temp,
        rec_list: temp3,
        view_flag: 1
      }).then(res => {
        if (res.data.code === 0) {
          // this.$message({
          //     type: 'success',
          //     message: '保存成功',
          // })
          this.htmlUrl = res.data.data;
          this.$emit('getSmpls');
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
        // this.cancel()
        (this.mailForm.mail_odd = ''), (this.mailForm.mail_company = '');
      });
    },
    //保存
    saveInfo() {
      // 接收smpl_id
      let temp = [];
      for (let i in this.smplPropList) {
        temp.push(this.smplPropList[i].smpl_id);
      }
      // 接收requ_id
      let temp2 = [];
      for (let i in this.smplPropList) {
        temp2.push(this.smplPropList[i].requ_id);
      }
      // 接收件人
      let temp3 = [];
      for (let i in this.dynamicValidateForm.domains) {
        var reg = /(?<=\()(.+?)(?=\))/g;
        var matchResult = this.dynamicValidateForm.domains[i].value.match(reg);
        temp3.push(matchResult[0]);
      }
      post(smplAPI.addSmplMail, {
        requ_id_list: temp2,
        smpl_id_list: temp,
        rec_list: temp3,
        view_flag: 0
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.$emit('getSmpls');
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
        this.cancel();
        (this.mailForm.mail_odd = ''), (this.mailForm.mail_company = '');
      });
    },
    // 表单验证
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.saveInfo();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 删除一行
    removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    // 增加一行
    addDomain() {
      this.dynamicValidateForm.domains.push({
        value: '',
        key: Date.now()
      });
    },
    // 打开导入弹窗
    importCustMail(val) {
      this.chooseNum = val;
      this.dialogVisible = true;
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 双击选择收件人
    dbClickJp(row) {
      this.dynamicValidateForm.domains[this.chooseNum].value = row.param1 + '(' + row.param2 + ')';
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.inputCss {
  width: 65%;
  margin-right: 10px;
  margin-bottom: 20px;
}
.box-card {
  margin-top: 30px;
}
.tip {
  color: grey;
  font-size: 12px;
  padding-left: 30px;
  padding-bottom: 20px;
}
/* 使table变成可点击的样式 */
.mtrTab {
  cursor: pointer;
}
.vd_right {
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
}
</style>
